<template>
<div>   
   <!-- <NavbarPF v-if="loggin == 1" :usuario="usuario"></NavbarPF> -->
    <section class="mt-2">
        <b-carousel :interval="4000"
                    background="#ababab">
          <b-carousel-slide :img-src="isMobile ? 'https://controlpromocionales.asistentemovistar.com.mx/promo_mobil3.jpg' : 'https://controlpromocionales.asistentemovistar.com.mx/promo_pc.jpg'">
          </b-carousel-slide>
        </b-carousel>
    </section>
   <br v-if="!isMobile"> 
  <div class="container">
    <form @submit="login">
        <div class="text-center">
            <!-- <h1 style="color: #4CAEF6" class="font-light">
            <strong style="color: #0085de;">Activaciones BTL</strong> 
            </h1> -->
             <hr style="border-top: 4px solid #f6f6f6 !important;  opacity :2;">
             <br v-if="!isMobile">
            <div class="row justify-content-center mt-5">
                <div class="col-md-11 mt-4">
                    <p style="font-size: 18px;">Ingresa con los siguientes datos:</p> 
                    <hr style="border-top: 4px solid #c5e7b3 !important;  opacity :4;">
                </div>
                <div class="col-md-4 mt-4">
                    <br>
                    <b-form-input placeholder="Correo"  v-model="email" type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD; height: 34%;"/>
                    <small v-if="errors && errors['email']" class="text-danger mt-2">
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                    </small>
                    <br>
                    <b-form-input placeholder="MRT" v-model="mrt"  type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD; height: 34%;"/>
                    <small v-if="errors && errors['mrt']" class="text-danger mt-2 text-left" >
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                    </small>
                </div>
                <div class="col-md-12">
                    <p v-if="errors && errors['general']" class="mt-4 lead text-danger">
                        <i class="fas fa-exclamation-circle"></i>
                        Datos incorrectos
                      </p>
                </div>
                <div class="col-md-7 mt-5 text-center">
                    <br><br>
                    <button :disabled="loader" class="btn btn-primary float-center btn-sm" type="submit" :style="isMobile ? 'border-radius: 26px; width: 50%;' : 'border-radius: 26px; width: 20%;'">
                        <span v-if="!loader">
                            Ingresar
                        </span>
                        <span v-else>
                        <b-spinner small></b-spinner> Ingresando...
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </form>
  </div>
  <br><br><template v-if="!isMobile"><br><br><br><br><br></template>
  <!-- <div>
    <div class="row justify-content-center">
        <div class="col-md-4"></div>
        <div class="col-md-3"></div>
        <div class="col-md-5">
            <div class="d-flex">
                <button :class="but == 1 ? 'btn btn-success' : 'btn btn-primary'" @click="butAccion(1)" v-if="perfil == 'Lider'">
                   <i class="far fa-plus"></i> Nueva Activación 
                </button>
                <button :class="but == 2 ? 'btn btn-success ml-3' : 'btn btn-primary ml-3' " @click="butAccion(2)">
                   <i class="far fa-list"></i> Activaciones
                </button>
            </div>
        </div>
    </div>
    <section class="mt-3">
        <b-carousel :interval="4000"
                    background="#ababab">
          <b-carousel-slide img-src="https://asistentemovistar.com.mx/activacion/bienvenido.jpg">
          </b-carousel-slide>
        </b-carousel>
    </section>
    <br><br>
    <template v-if="but == 1">
       
    </template>
    <template v-else>
     
    </template>
    
  </div> -->
</div>
</template>

<script>
// import LogoSVG from "../../layouts/LogoSVG.vue";
// import NavbarPF from "../activaciones/NavActivacion.vue";
// import Registro from "../activaciones/Registro.vue";
// import Listado from "../activaciones/Listado.vue";
// import InitController from "@/utils/InitController";
import Cookies from "js-cookie";
import Swal from 'sweetalert2' 
export default {
    name:"Activaciones",
    // components: {
    //     LogoSVG,
    //     NavbarPF,
    //     Registro,
    //     Listado
    // },
    data() {
        return {
          errors:[],
          loader:false,
          email:"",
          mrt:"",
          isMobile: this.$mobile(),
        }
    },
    methods:{
        login(e) {
            e.preventDefault();
            this.errors = [];
            this.loader = true;
            var form = {};
            var url = "";

                form.mrt = this.mrt
                form.email = this.email
                url = "embajador";

            this.$api.post('auth/login/' + url, form, true).then(({data}) => {
                console.log(data.data);
                if (data.data.user_data.perfiles[0].usuario_perfil_id != 2 && data.data.user_data.perfiles[0].usuario_perfil_id != 10 ) {
                   Swal.fire({
                    icon: 'error',
                    title: 'Aviso',
                    text: 'Este perfil no cuenta con acceso al micrositio' 
                    }) 
                    this.loader = false;
                }else{
                    this.$store.dispatch("setLogin", data.data);
                    this.$router.push("/");
                    this.loader = false;
                }
            }).catch(e => {
                console.log(e);
                this.loader = false;
                if (e.response.data.errors) {
                    this.errors = e.response.data.errors;
                } else {
                  alert("Ocurrió un error desconocido, vuelve a intentarlo más tarde")
                }
            });
        },
        cerrarSesion(){
            this.$store.commit(
                "setLoginStatus",
                false
            );
            this.$store.commit(
                "setInfoUser",
                null
            );    
            Cookies.remove("session_id");
            location.reload();
        },
    }
}
</script>

<style>

</style>